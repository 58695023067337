import React from "react";
import { useTranslation } from "react-i18next";

import "./MandatoryOptionsBadge.scss";
import "./InitialsBadge.scss";

function MinApproversBadge({ minValue, stepApproverLen }) {
  const { t } = useTranslation("company");
  const getText = () => {
    if (minValue === 1) {
      return t("approvalFlows.options.mandatoryApprovalFrom_any");
    }
    if (minValue === stepApproverLen) {
      return t("approvalFlows.options.mandatoryApprovalFrom_all");
    }
    return `${minValue}/${stepApproverLen}`;
  };
  if (stepApproverLen === 1) {
    return null;
  }
  return (
    <span className="mandatory-options-badge mr-1" title={getText()}>
      {getText()}
    </span>
  );
}

export { MinApproversBadge };
