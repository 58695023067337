import React from "react";
import { useTranslation } from "react-i18next";
import cx from "classnames";

import "./MandatoryOptionsBadge.scss";
import "./InitialsBadge.scss";

function MinApproversBadge({ minValue, stepApproverLen, mode = "preview", approvalOption }) {
  const { t } = useTranslation("company");

  const getText = () => {
    if (minValue === 1) {
      return t("approvalFlows.options.mandatoryApprovalFrom_any");
    }
    if (minValue === stepApproverLen) {
      return t("approvalFlows.options.mandatoryApprovalFrom_all");
    }
    return `${minValue}/${stepApproverLen}`;
  };

  if (mode === "form" && (minValue === null || approvalOption === null)) {
    return (
      <span className="mandatory-options-badge error-badge" title={t("approvalFlows.options.error_missingOption")}>
        ?
      </span>
    );
  }

  if (stepApproverLen === 1) {
    return null;
  }

  return (
    <span className="mandatory-options-badge" title={getText()}>
      {getText()}
    </span>
  );
}

function NewMinApproversBadge({ minValue, stepApproverLen, mode = "preview", approvalOption, disabled = false }) {
  const { t } = useTranslation("company");

  const getText = () => {
    if (approvalOption === "any") {
      return t("approvalFlows.options.mandatoryApprovalFrom_any");
    }
    if (approvalOption === "all") {
      return t("approvalFlows.options.mandatoryApprovalFrom_all");
    }
    return `${minValue}/${stepApproverLen}`;
  };

  if (mode === "form" && (minValue === null || approvalOption === null)) {
    return (
      <span className="mandatory-options-badge badge-error" title={t("approvalFlows.options.error_missingOption")}>
        ?
      </span>
    );
  }

  const badgeClass = cx("mandatory-options-badge", {
    "badge-any": approvalOption === "any",
    "badge-all": approvalOption === "all",
    "badge-other": approvalOption === "other",
    "badge-disabled": disabled,
  });

  return (
    <span
      className={badgeClass}
      title={
        disabled
          ? t("approvalFlows.options.disabledTooltip")
          : t("approvalFlows.options.tooltip", { option: getText() })
      }
    >
      {getText()}
    </span>
  );
}

export { MinApproversBadge, NewMinApproversBadge };
