import { useTranslation } from "react-i18next";
import React, { useCallback } from "react";
import { Button, Modal } from "react-bootstrap";
import _ from "lodash";

import { DraggableModalDialog } from "components/modals/DraggableModalDialog";
import { RocketLoader } from "components/ui/loaders";
import useAsync from "hooks/useAsync";
import ApprovalFlowPreview from "components/others/ApprovalFlowPreview";
import { approvalFlows } from "api2/companies";
import * as selectAPI from "api2/selects";

function ApprovalFlowPickerModal({ handleClose, companyId, setFieldValue }) {
  const { t } = useTranslation("ver");
  const employeeDataSource = useCallback(
    (cancelToken) =>
      selectAPI.companyEmployees(companyId, {}, "user_id", { cancelToken }).then((data) => {
        const _data = data.map((emp) => ({
          ...emp,
          first_name: emp.user_first_name,
          last_name: emp.user_last_name,
        }));
        console.log("_data");
        console.log(_data);
        return _.keyBy(_data, (emp) => emp.user_id);
      }),
    [companyId]
  );
  const [{ data: employeesByUserId, loading: loadingApprovers }] = useAsync(employeeDataSource, {});

  const dataSource = useCallback((cancelToken) => approvalFlows.list(companyId, { status: "active" }), [companyId]);
  const [{ data, loading }] = useAsync(dataSource, []);

  return (
    <Modal
      animation={false}
      scrollable
      show
      onHide={() => handleClose()}
      dialogClassName="task-modal"
      dialogAs={DraggableModalDialog}
      size="xl"
    >
      <Modal.Header closeButton>
        <Modal.Title className="m-0">{t("Approval flows")}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {loadingApprovers || loading ? (
          <RocketLoader />
        ) : (
          <>
            {data.map((flow) => (
              <div className="card-box p-2 shadowed">
                <ApprovalFlowPreview
                  companyId={companyId}
                  title={flow.title}
                  steps={flow.steps}
                  employeesByUserId={employeesByUserId}
                />
                <div className="text-right">
                  <Button
                    variant="primary"
                    type="button"
                    className="mt-1"
                    onClick={() => {
                      setFieldValue("approval_flow_id", flow.id);
                      handleClose();
                    }}
                  >
                    {t("common:actions.select")}
                  </Button>
                </div>
              </div>
            ))}
          </>
        )}
      </Modal.Body>
    </Modal>
  );
}

export default ApprovalFlowPickerModal;
