import { ErrorMessage, useField } from "formik";
import { Form, InputGroup } from "react-bootstrap";
import React from "react";

function MinApproversInput({ name, label, assignedUsersCount = 0, required, onChange, ...props }) {
  const [field, meta, helpers] = useField({ name, ...props });

  const handleOnChange = (e) => {
    const { value } = e.target;
    const numericValue = Math.max(1, Math.min(Number(value), assignedUsersCount));
    helpers.setValue(numericValue);
    if (onChange) {
      onChange(numericValue);
    }
  };

  return (
    <Form.Group controlId={name}>
      {label && (
        <Form.Label>
          {label} {required && <span className="text-danger">*</span>}
        </Form.Label>
      )}
      <InputGroup>
        <Form.Control
          {...field}
          type="number"
          min={1}
          max={assignedUsersCount}
          onChange={handleOnChange}
          value={field.value || ""}
          isInvalid={meta.touched && !!meta.error}
          autoComplete="off"
          {...props}
        />
        <InputGroup.Append>
          <InputGroup.Text>{`/ ${assignedUsersCount}`}</InputGroup.Text>
        </InputGroup.Append>
      </InputGroup>
      <Form.Control.Feedback type="invalid">
        <ErrorMessage name={name} />
      </Form.Control.Feedback>
    </Form.Group>
  );
}

export default MinApproversInput;
