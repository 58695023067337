import React, { useCallback } from "react";
import _ from "lodash";
import cx from "classnames";
import { Tooltip, OverlayTrigger } from "react-bootstrap";
import useAsync from "hooks/useAsync";
import { MinApproversBadge } from "components/ui/badges/MandatoryOptionsBadge";
import { approvalFlows } from "api2/companies";
import { Loader } from "components/ui/loaders";

function ShortApproverItem({ approverId, employeesByUserId, deniedByIds, approvedByIds, isInNextStep = false }) {
  const getApproverInitials = () => {
    if (employeesByUserId[approverId]) {
      return `${employeesByUserId[approverId].first_name.substring(0, 1)}${employeesByUserId[approverId].last_name.substring(0, 1)}`;
    }
    return `..`;
  };
  return (
    <div className="employee-initials-badge-wrapper">
      <OverlayTrigger
        placement="bottom"
        overlay={
          <Tooltip>
            {employeesByUserId[approverId].first_name} {employeesByUserId[approverId].last_name}
          </Tooltip>
        }
      >
        <span
          className={cx("initials-badge", {
            approved: approvedByIds.includes(approverId),
            denied: deniedByIds.includes(approverId),
            next: isInNextStep,
          })}
        >
          {getApproverInitials(approverId)}
        </span>
      </OverlayTrigger>
    </div>
  );
}

function ApproverItem({ approverId, employeesByUserId, deniedByIds, approvedByIds, isInNextStep = false }) {
  console.log("x1");
  console.log(approverId);
  console.log(employeesByUserId);
  return (
    <span
      className={cx("approvers-full-name-badges badge badge-pill", {
        approved: approvedByIds.includes(approverId),
        denied: deniedByIds.includes(approverId),
        next: isInNextStep,
      })}
    >
      {employeesByUserId[approverId].first_name} {employeesByUserId[approverId].last_name}
    </span>
  );
}

function ApprovalFlowPreview({
  companyId,
  title,
  steps,
  employeesByUserId,
  deniedByIds = [],
  approvedByIds = [],
  cutPassedStepsMode = true,
  fullNameMode = false,
  currentActiveStepId = null,
}) {
  console.log("approvedByIds");
  console.log("deniedById");
  console.log("employeesByUserId");
  console.log(approvedByIds);
  console.log(deniedByIds);
  console.log(employeesByUserId);
  console.log("currentActiveStep");
  console.log(currentActiveStepId);
  const ApproverItemDisplay = fullNameMode ? ApproverItem : ShortApproverItem;
  const extendedSteps = steps.map((step) => ({
    ...step,
    isFinished:
      step.approvers_ids.filter((stepApproverId) => approvedByIds.includes(stepApproverId)).length >=
      step.min_approvers_counter_condition,
    isNext: currentActiveStepId && currentActiveStepId < step.id,
  }));

  const shouldCutStep = (extendedStep) => {
    return cutPassedStepsMode && extendedStep.isFinished;
  };
  const shouldIncludeApprover = (extendedStep, approverId) => {
    if (!shouldCutStep(extendedStep)) {
      return true;
    }
    return approvedByIds.includes(approverId) || deniedByIds.includes(approverId);
  };
  return (
    <div>
      {extendedSteps.map((step, index) => (
        <>
          {index > 0 && <i className="fe-chevron-right" />}
          {!shouldCutStep(step) && (
            <MinApproversBadge
              minValue={step.min_approvers_counter_condition}
              stepApproverLen={step.approvers_ids.length}
            />
          )}
          {step.approvers_ids.map(
            (approverId) =>
              shouldIncludeApprover(step, approverId) && (
                <ApproverItemDisplay
                  approverId={approverId}
                  employeesByUserId={employeesByUserId}
                  approvedByIds={approvedByIds}
                  deniedByIds={deniedByIds}
                  isInNextStep={step.isNext}
                />
              )
          )}
        </>
      ))}
    </div>
  );
}

function ApprovalFlowFetchPreview({
  companyId,
  approvalFlowId,
  deniedByIds = [],
  approvedByIds = [],
  fullNameMode = false,
  currentActiveStepId = null,
}) {
  const dataSource = useCallback(
    (cancelToken) => approvalFlows.details(companyId, approvalFlowId),
    [companyId, approvalFlowId]
  );
  const [{ data, loading }] = useAsync(dataSource, []);
  if (loading) {
    return <Loader />;
  }
  const approversById = _.keyBy(data.approvers, (user) => user.id);
  console.log("approvedByIdsXXXX");
  console.log(approvedByIds);
  return (
    <ApprovalFlowPreview
      companyId={companyId}
      title={data.title}
      steps={data.steps}
      employeesByUserId={approversById}
      deniedByIds={deniedByIds}
      approvedByIds={approvedByIds}
      cutPassedStepsMode
      fullNameMode={fullNameMode}
      currentActiveStepId={currentActiveStepId}
    />
  );
}

export default ApprovalFlowPreview;
export { ApprovalFlowFetchPreview };
