import React from "react";
import { codesFor, HasPermCode } from "components/perms";
import SupplierInvoiceListPage from "./SupplierInvoiceListPage";
import { useCompanyState } from "../../../../hooks/useCompany";
import { useAuthState } from "../../../../hooks/useAuth";

function ApproveSupplierInvoiceListPage() {
  const allowedModes = [
    { value: "approvalAndDraft", label: "common:statuses.all", variant: "toggle" },
    { value: "approval", label: "common:statuses.prelim", variant: "toggle-orange" },
    { value: "draft", label: "common:statuses.draft", variant: "toggle-grey" },
  ];
  const canApprove = HasPermCode(codesFor.supplierInvoices.approve);
  const { perms, approval_flow_enabled } = useCompanyState();
  const isConsultManage = HasPermCode(codesFor.consultManage.enabled) || perms.isAdmin;
  const { user } = useAuthState();
  const extraInitialFilters =
    isConsultManage || !approval_flow_enabled
      ? {}
      : { assignee_approver: user.id, assignee_approver_current_active_only: true };
  return (
    <SupplierInvoiceListPage
      tableId="prelimSupplierInvoices"
      initialMode={allowedModes[1].value}
      allowedModes={allowedModes}
      selectable={canApprove}
      extraInitialFilters={extraInitialFilters}
    />
  );
}

export default ApproveSupplierInvoiceListPage;
