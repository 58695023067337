import React, { useState } from "react";
import { Button, ButtonGroup } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Link, useResolvedPath } from "react-router-dom";
import { formatISO } from "date-fns";

import { FilterButton } from "components/ui/buttons";
import useTable from "components/tables/btable/useTable";
import useModal from "hooks/useModal";
import { FileUrlModal } from "components/modals";
import {
  CheckboxFilter,
  CompanyEmployeeFilter,
  DateFilter,
  DateRangeFilters,
  MoneyRangeFilter,
  SearchFilter,
} from "components/filters";
import { PermCodeRequired } from "components/perms";
import { codesForSupplierInvoices } from "components/perms/PermCodes";
import qs from "qs";
import { formatDate } from "utils/date";

function SupplierInvoiceFilters({ companyId, forStatus, allowedModes, approvalFlowEnabled }) {
  const fbdModal = useModal();
  const csvModal = useModal();
  const url = useResolvedPath("").pathname;
  const { filters, setFilters } = useTable();
  const { t } = useTranslation("common");
  const filtersCount =
    !!filters.booking_date__start +
    !!filters.booking_date__end +
    !!filters.ledger_date +
    !!filters.due_date__start +
    !!filters.due_date__end +
    !!filters.amount_sek_min +
    !!filters.amount_sek_max +
    !!filters.assignee_approver;
  const [more, setMore] = useState(!!filtersCount);
  const show = {
    createBtn: !forStatus,
  };

  function openFlashbackPDF(ledgerDate) {
    const param = formatISO(ledgerDate, { representation: "date" });
    fbdModal.open(`/supplier-invoices/flashback/pdf/?ledger_date=${param}`);
  }

  const formatDateFilters = () => {
    const formattedFilters = { ...filters };
    formattedFilters.booking_date__start = formatDate(formattedFilters.booking_date__start);
    formattedFilters.booking_date__end = formatDate(formattedFilters.booking_date__end);
    formattedFilters.due_date__start = formatDate(formattedFilters.due_date__start);
    formattedFilters.due_date__end = formatDate(formattedFilters.due_date__end);
    formattedFilters.ledger_date = formatDate(formattedFilters.ledger_date);
    return formattedFilters;
  };

  const openCSVModal = () => {
    const formattedFilters = formatDateFilters(filters);
    const params = qs.stringify(
      {
        ...formattedFilters,
      },
      { indices: false, skipNulls: true }
    );
    csvModal.open(`/supplier-invoices/report/csv/?${params}`);
  };

  return (
    <>
      <section className="table-filters-left">
        <SearchFilter onFilter={setFilters} defaultValue={filters.term} />
        <FilterButton active={more} count={filtersCount} onClick={() => setMore(!more)} />
        {allowedModes ? (
          <ButtonGroup aria-label="Status filter" className="status-filter">
            {allowedModes.map((mode) => (
              <Button
                key={mode.value}
                variant={mode.variant}
                active={filters.mode === mode.value}
                onClick={() => setFilters({ mode: mode.value })}
              >
                {t(mode.label)}
              </Button>
            ))}
          </ButtonGroup>
        ) : (
          <ButtonGroup aria-label="Status filter" className="status-filter">
            <Button
              variant="toggle"
              active={!filters.mode || filters.mode === "all"}
              onClick={() => setFilters({ mode: "" })}
            >
              {t("common:statuses.all")}
            </Button>
            <Button
              variant="toggle-grey"
              active={filters.mode === "draft"}
              onClick={() => setFilters({ mode: "draft" })}
            >
              {t("common:statuses.draft")}
            </Button>
            <Button
              variant="toggle-purple"
              active={filters.mode === "investigate"}
              onClick={() => setFilters({ mode: "investigate" })}
            >
              {t("common:statuses.investigate")}
            </Button>
            <Button
              variant="toggle-orange"
              active={filters.mode === "prelim"}
              onClick={() => setFilters({ mode: "prelim" })}
            >
              {t("common:statuses.inApproval")}
            </Button>
            <Button
              variant="toggle-red-grey"
              active={filters.mode === "unpaid"}
              onClick={() => setFilters({ mode: "unpaid" })}
            >
              {t("common:statuses.unpaid")}
            </Button>
            <Button
              variant="toggle-blue"
              active={filters.mode === "sent_bank"}
              onClick={() => setFilters({ mode: "sent_bank" })}
            >
              {t("common:statuses.sentBank")}
            </Button>
            <Button
              variant="toggle-green"
              active={filters.mode === "paid"}
              onClick={() => setFilters({ mode: "paid" })}
            >
              {t("common:statuses.paid")}
            </Button>
          </ButtonGroup>
        )}
      </section>
      <section className="table-filters-right">
        <PermCodeRequired code={codesForSupplierInvoices.create}>
          {show.createBtn && (
            <Link to={`${url}/form`} className="btn btn-blue">
              <i className="fas fa-plus mr-1" /> {t("common:actions.create")}
            </Link>
          )}
        </PermCodeRequired>
        {!forStatus && (
          <PermCodeRequired code={codesForSupplierInvoices.enabled}>
            <Button variant="secondary" onClick={openCSVModal}>
              {t("reports:reportCSV")}
            </Button>
          </PermCodeRequired>
        )}
      </section>
      {more && (
        <>
          <section className="more-filters">
            {!forStatus && (
              <DateFilter
                label={t("common:dates.ledgerDate")}
                defaultValue={filters.ledger_date}
                onChange={(date) => setFilters({ ledger_date: date })}
              >
                {filters.ledger_date && (
                  <Button className="btn-block p-0" onClick={() => openFlashbackPDF(filters.ledger_date)}>
                    PDF
                  </Button>
                )}
              </DateFilter>
            )}
            <DateRangeFilters
              label={t("common:dates.invoiceDate")}
              dateStart={filters.booking_date__start}
              dateEnd={filters.booking_date__end}
              onChange={({ start, end }) =>
                setFilters({
                  booking_date__start: start,
                  booking_date__end: end,
                })
              }
            />
            <DateRangeFilters
              label={t("common:dates.dueDate")}
              dateStart={filters.due_date__start}
              dateEnd={filters.due_date__end}
              onChange={({ start, end }) => setFilters({ due_date__start: start, due_date__end: end })}
            />
            <MoneyRangeFilter
              label={t("common:money.amountRange")}
              numberFrom={filters.amount_sek_min}
              numberTo={filters.amount_sek_max}
              onChange={(values) =>
                setFilters({
                  amount_sek_min: values.numberFrom,
                  amount_sek_max: values.numberTo,
                })
              }
            />
            {approvalFlowEnabled && (
              <CompanyEmployeeFilter
                label={t("common:assignee")}
                isClearable
                companyId={companyId}
                name="assignee_approver"
                valueField="user_id"
                onFilter={(selected) => {
                  setFilters({
                    ...filters,
                    ...selected,
                    ...{
                      assignee_approver_current_active_only: selected.assignee_approver
                        ? filters.assignee_approver_current_active_only
                        : false,
                    },
                  });
                }}
                defaultValue={filters.assignee_approver}
              />
            )}
          </section>
          <section>
            <CheckboxFilter
              wrapperClass="hacked-checkbox d-block"
              label={t("si:foreignInvoices")}
              name="foreign_invoices"
              defaultChecked={filters.foreign_invoices}
              onFilter={setFilters}
            />
            {approvalFlowEnabled && (
              <CheckboxFilter
                wrapperClass="hacked-checkbox d-block"
                label={t("Active for assignee")}
                name="assignee_approver_current_active_only"
                defaultChecked={!!filters.assignee_approver_current_active_only}
                defaultValue={!!filters.assignee_approver_current_active_only}
                onFilter={setFilters}
                disabled={!filters.assignee_approver}
              />
            )}
          </section>
        </>
      )}
      {csvModal.show && (
        <FileUrlModal
          companyId={companyId}
          fileUrl={csvModal.data}
          downloadFileName="levreskontra.csv"
          handleClose={csvModal.close}
        />
      )}
      {fbdModal.show && <FileUrlModal fileUrl={fbdModal.data} companyId={companyId} handleClose={fbdModal.close} />}
    </>
  );
}

export default SupplierInvoiceFilters;
