import React, { useState } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import cx from "classnames";
import "./Badges.scss";

function EmployeeInitialsBadge({ approverId, employeesByUserId, mode = "preview", onRemove, ...props }) {
  const [isHovered, setIsHovered] = useState(false);

  const getApproverInitials = () => {
    const user = employeesByUserId[approverId];
    if (user?.user_first_name && user?.user_last_name) {
      return `${user.user_first_name[0]}${user.user_last_name[0]}`;
    }
    return "..";
  };

  const fullName = employeesByUserId[approverId]?.user_name || `User ID: ${approverId}`;

  return (
    <div
      className="employee-initials-badge-wrapper"
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <OverlayTrigger placement="bottom" overlay={<Tooltip>{String(fullName)}</Tooltip>}>
        <span className={cx("initials-badge", { "badge-hover": isHovered && onRemove })}>
          {mode === "form" && isHovered && onRemove ? (
            <span
              className="fe-trash-2 trash-icon"
              onClick={(e) => {
                e.stopPropagation();
                onRemove?.(approverId);
              }}
            />
          ) : (
            getApproverInitials()
          )}
        </span>
      </OverlayTrigger>
    </div>
  );
}

function ApproversFullNameBadge({ approvers, employeesByUserId, className }) {
  const getFullName = (id) => {
    const user = employeesByUserId[id];
    return user?.user_name || `User ID: ${id}`;
  };

  return (
    <div className={`employee-names-badge-wrapper ${className}`}>
      {approvers.map((id, index) => (
        <React.Fragment key={id}>
          <span className="employee-name">{getFullName(id)}</span>
          {index < approvers.length - 1 && <span className="separator">•</span>}
        </React.Fragment>
      ))}
    </div>
  );
}

export { ApproversFullNameBadge };
export default EmployeeInitialsBadge;
