import React, { useCallback, useContext, useState } from "react";
import { Alert, Button, Card, Col, Row } from "react-bootstrap";
import { toast } from "react-toastify";
import { NewMinApproversBadge } from "components/ui/badges/MandatoryOptionsBadge";
import cx from "classnames";
import "./ApprovalFlowsPage.scss";
import _ from "lodash";
import ApprovalFlowForm from "components/forms/company-settings-forms/ApprovalFlowForms/ApprovalFlowForm";
import { approvalFlows } from "api2/companies";
import { handleFormErrors } from "api/errors";
import useAsync from "hooks/useAsync";
import { RocketLoader } from "components/ui/loaders";
import EmployeeInitialsBadge, { ApproversFullNameBadge } from "components/ui/badges/EmployeeInitialsBadge";
import { useTranslation } from "react-i18next";
import * as selectAPI from "api2/selects";
import { CompanySettingStateContext } from "state/providers/CompanySettingProvider";

function AddFlowButton({ onClick }) {
  const { t } = useTranslation("company");
  return (
    <Button onClick={onClick} className="flow-button" variant="outline-secondary">
      <i className="fas fa-plus" /> {t("approvalFlows.buttons_addFlow")}
    </Button>
  );
}

function ApprovalFlowCard({ flow, employeesByUserId, onEdit, isEditingOrAddingFlow, isLongVersion }) {
  const steps = flow?.steps || [];
  const { t } = useTranslation("company");

  return (
    <Card className={cx("approval-flow-card", { default: flow.is_default })}>
      <Card.Body>
        <div className="title-wrapper d-flex align-items-center justify-content-between">
          <h6 className={cx("card-title", { "default-title": flow.is_default })}>{flow.title}</h6>
          {!isEditingOrAddingFlow && (
            <i
              className="fe-edit-2"
              onClick={() => onEdit(flow)}
              style={{ cursor: "pointer" }}
              title={t("approvalFlows.buttons_editFlow")}
            />
          )}
        </div>
        <div className="steps-container">
          {steps.map((step, stepIndex) => (
            <React.Fragment key={stepIndex}>
              <div className="step-container">
                <NewMinApproversBadge
                  minValue={step.min_approvers_counter_condition}
                  stepApproverLen={step.approvers_ids.length}
                  approvalOption={step.approval_option}
                />
                <div className="approvers-wrapper">
                  {isLongVersion ? (
                    <ApproversFullNameBadge approvers={step.approvers_ids} employeesByUserId={employeesByUserId} />
                  ) : (
                    step.approvers_ids.map((userId) => (
                      <EmployeeInitialsBadge key={userId} approverId={userId} employeesByUserId={employeesByUserId} />
                    ))
                  )}
                </div>
              </div>
              {stepIndex < flow.steps.length - 1 && <span className="arrow">></span>}
            </React.Fragment>
          ))}
        </div>
      </Card.Body>
    </Card>
  );
}

function ApprovalFlowsPage() {
  const { info } = useContext(CompanySettingStateContext);
  const { id: companyId, approval_flow_enabled: approvalFlowEnabled } = info;
  const [isCreatingFlow, setIsCreatingFlow] = useState(false);
  const [currentFlow, setCurrentFlow] = useState(null);
  const [isEditingOrAddingFlow, setIsEditingOrAddingFlow] = useState(false);
  const [isLongVersion, setIsLongVersion] = useState(false);
  const { t } = useTranslation("company");
  const dataSource = useCallback(
    (cancelToken) => approvalFlows.list(companyId, { status: "active" }, { cancelToken }),
    [companyId]
  );
  const [{ data: approvalFlowsData, loading }, { reload }] = useAsync(dataSource, []);

  const employeeDataSource = useCallback(
    (cancelToken) =>
      selectAPI.companyEmployees(companyId, {}, "user_id", { cancelToken }).then((data) => {
        return _.keyBy(data, (emp) => emp.user_id);
      }),
    [companyId]
  );
  const [{ data: employeesByUserId }] = useAsync(employeeDataSource, {});

  if (loading) {
    return <RocketLoader />;
  }

  if (!approvalFlowEnabled) {
    return (
      <div className="page-alert pt-2">
        <Alert variant="warning">
          <span>
            <i className="fas fa-exclamation mr-2" />
            {t("approvalFlows.errors_approvalFlowNotEnabled")}
          </span>
        </Alert>
      </div>
    );
  }

  const handleAdd = () => {
    setIsCreatingFlow(true);
    setIsEditingOrAddingFlow(true);
  };

  const handleEdit = (flow) => {
    const existingFlow = {
      ...flow,
      steps: flow?.steps.map((step) => {
        const ids = step.approvers_ids ?? [];

        return {
          ...step,
          approvers_ids: ids,
          approvers: ids
            .map((id) => employeesByUserId[id])
            .filter(Boolean)
            .map((user) => ({
              value: user.id,
              label: user.user_name,
              id: user.id,
              user_id: user.user_id,
              user_name: user.user_name,
              account: user.account,
            })),
        };
      }),
    };
    setCurrentFlow(existingFlow);
    setIsCreatingFlow(true);
    setIsEditingOrAddingFlow(true);
  };

  const handleCancel = () => {
    setIsCreatingFlow(false);
    setCurrentFlow(null);
    setIsEditingOrAddingFlow(false);
  };

  const handleSave = async (payload, setErrors) => {
    try {
      await approvalFlows.save(companyId, payload);
      reload();
      handleCancel();
      toast.success(t("msg:saved"));
    } catch (error) {
      handleFormErrors(error, setErrors);
    }
  };

  const handleDelete = async (approvalFlowId, setErrors) => {
    try {
      await approvalFlows.delete(companyId, approvalFlowId);
      reload();
      handleCancel();
      toast.success(t("msg:deleted"));
    } catch (error) {
      handleFormErrors(error, setErrors);
    }
  };

  const newFlow = { id: null, title: "", steps: [], is_default: false, for_object_type: "si" };

  const handleToggleVersion = () => {
    setIsLongVersion((prev) => !prev);
  };

  return (
    <Card>
      <Card.Body>
        <div className="flow-list">
          <div className="approval-flow-section mb-4">
            <div className="d-flex align-items-center justify-content-between mb-3">
              <div className="title">{t("approvalFlows.labels_supplierInvoicesFlow")}</div>
              <Button
                variant="outline-primary"
                className="toggle-button"
                onClick={handleToggleVersion}
                title={isLongVersion ? t("approvalFlows.buttons.showShort") : t("approvalFlows.buttons.showLong")}
              >
                <i className={isLongVersion ? "fe-grid" : "fe-server"} />
              </Button>
            </div>

            <Row>
              {Array.isArray(approvalFlowsData) &&
                approvalFlowsData.map((flow) => (
                  <Col key={flow.id}>
                    <ApprovalFlowCard
                      flow={flow}
                      employeesByUserId={employeesByUserId}
                      onEdit={handleEdit}
                      isEditingOrAddingFlow={isEditingOrAddingFlow}
                      isLongVersion={isLongVersion}
                    />
                  </Col>
                ))}
              {!isEditingOrAddingFlow && (approvalFlowsData?.length || 0) < 10 && (
                <Col>
                  <AddFlowButton onClick={handleAdd} />
                </Col>
              )}
            </Row>
          </div>
        </div>

        {isCreatingFlow && (
          <div className="mt-4">
            <ApprovalFlowForm
              initialData={currentFlow || newFlow}
              companyId={companyId}
              onSave={handleSave}
              onCancel={handleCancel}
              onDelete={handleDelete}
              titleForm={
                currentFlow
                  ? t("approvalFlows.labels_editSupplierInvoiceFlow")
                  : t("approvalFlows.labels_newSupplierInvoiceFlow")
              }
              employeesByUserId={employeesByUserId}
              approvalFlowsDataLen={approvalFlowsData?.length || 0}
            />
          </div>
        )}
      </Card.Body>
    </Card>
  );
}

export default ApprovalFlowsPage;
