import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Card, Row, Col, Button, Alert } from "react-bootstrap";
import { Formik, Form, FieldArray, ErrorMessage } from "formik";
import * as yup from "yup";
import { FormGroup } from "components/formik";
import cx from "classnames";
import { NewMinApproversBadge } from "components/ui/badges/MandatoryOptionsBadge";
import CompanyEmployeePicker from "components/formik/CompanyEmployeePicker";
import { SubmitButton } from "components/ui/buttons";
import EmployeeInitialsBadge from "components/ui/badges/EmployeeInitialsBadge";
import RemoveButton from "components/ui/buttons/RemoveButton";
import MinApproversInput from "./MinApproversInput";
import "./ApprovalFlowForm.scss";

function ApprovalFlowForm({
  initialData,
  titleForm,
  employeesByUserId,
  onSave,
  onCancel,
  onDelete,
  companyId,
  approvalFlowsDataLen,
}) {
  const { t } = useTranslation("company");
  const emptyStep = {
    approvers: [],
    approvers_ids: [],
    min_approvers_counter_condition: null,
    approval_option: null,
  };

  const initialSteps = initialData?.steps && initialData.steps.length > 0 ? initialData.steps : [emptyStep];

  const [activeStep, setActiveStep] = useState(initialSteps.length - 1);

  const transformStep = (step) => {
    const onlyUserIds = step.approvers?.map((user) => user.user_id) || [];
    switch (step.approval_option) {
      case "other":
        return {
          approvers_ids: onlyUserIds,
          approval_option: step.approval_option,
          min_approvers_counter_condition: step.min_approvers_counter_condition,
        };
      case "all":
        return {
          approvers_ids: onlyUserIds,
          approval_option: step.approval_option,
          min_approvers_counter_condition: onlyUserIds.length,
        };
      case "any":
        return {
          approvers_ids: onlyUserIds,
          approval_option: step.approval_option,
          min_approvers_counter_condition: 1,
        };
      default:
        return {
          approvers_ids: onlyUserIds,
          approval_option: step.approval_option,
          min_approvers_counter_condition: 1,
        };
    }
  };

  const formikProps = {
    validateOnBlur: true,
    validateOnChange: true,
    initialValues: {
      ...initialData,
      steps: initialSteps,
    },
    validationSchema: yup.object().shape({
      title: yup.string().required("Flow title is required"),
      steps: yup
        .array()
        .of(
          yup.object().shape({
            approvers_ids: yup.array().min(1, "At least one approver is required").required("Approvers are required"),
            min_approvers_counter_condition: yup
              .number()
              .min(1, "Minimum approvers must be at least 1")
              .test(
                "max-min-approvers",
                "Minimum approvers cannot exceed the number of assigned approvers",
                function (value) {
                  const approvers_ids = this.parent.approvers_ids || [];
                  return value <= approvers_ids.length;
                }
              )
              .required(),
          })
        )
        .min(1, "At least one step is required")
        .max(3)
        .required("Steps are required"),
    }),
    onSubmit: async (values, { setErrors }) => {
      const hasInvalidStep = values.steps.some((step) => !step.approvers_ids || step.approvers_ids.length === 0);

      if (hasInvalidStep) {
        setErrors({ _steps: t("approvalFlows.errors_invalidSteps") });
        return;
      }

      const transformedSteps = values.steps.map(transformStep);
      const payload = {
        id: values?.id,
        title: values.title || "",
        is_default: values.is_default || false,
        steps: transformedSteps,
        for_object_type: "si",
      };

      try {
        await onSave(payload, setErrors);
      } catch (err) {
        console.error("API error:", err);
      }
    },
  };

  const getExcludedUserIds = (steps) => {
    return steps.flatMap((step) => step.approvers_ids || []);
  };

  return (
    <div className="approval-flow-wrapper">
      <div className="approval-flow-title">{titleForm}</div>
      {initialData.id && (
        <Alert variant="warning">
          <i className="fe-info" /> {t("approvalFlows.errors_editingWarning")}
        </Alert>
      )}
      <Formik {...formikProps}>
        {({ values, setFieldValue, setErrors, isSubmitting, errors }) => {
          const excludedUserIds = getExcludedUserIds(values.steps, activeStep);
          console.log("ERRRORS", errors);
          return (
            <Form>
              <Card className="approval-flow-card">
                <Card.Body className="approval-flow-card-body">
                  <FieldArray name="steps">
                    {({ push, remove }) => (
                      <>
                        <Row>
                          {values.steps.map((step, index) => {
                            const stepHasError =
                              errors.steps &&
                              errors.steps[index] &&
                              (errors.steps[index].approvers_ids ||
                                errors.steps[index].min_approvers_counter_condition);
                            return (
                              <React.Fragment key={index}>
                                <Col className="approval-flow-step-col">
                                  <div
                                    className={cx("approval-flow-step-wrapper", {
                                      "active-step": activeStep === index,
                                      "completed-step": index < activeStep,
                                    })}
                                    onClick={() => setActiveStep(index)}
                                    role="button"
                                    tabIndex={0}
                                    style={{ cursor: "pointer" }}
                                  >
                                    <div className="approval-flow-step-header">
                                      <div className="approval-flow-step-title">
                                        {t("approvalFlows.labels_step")} {index + 1}
                                        {values.steps.length !== 1 && (
                                          <Button
                                            variant="link"
                                            className="p-0 text-danger float-right"
                                            disabled={values.steps.length === 1}
                                            onClick={(e) => {
                                              e.stopPropagation();
                                              remove(index);
                                              setActiveStep((prev) => Math.max(0, prev - 1));
                                            }}
                                          >
                                            <i className="fe-trash-2" />
                                          </Button>
                                        )}
                                      </div>
                                      <div
                                        className={cx("approval-flow-step-bar", {
                                          "active-step": activeStep === index,
                                          "completed-step": index < activeStep,
                                          "error-step": stepHasError,
                                        })}
                                      >
                                        {activeStep === index && <div className="approval-flow-step-marker" />}
                                      </div>
                                    </div>
                                    <div
                                      className={cx("approval-flow-step-body", {
                                        "active-step-body": activeStep === index,
                                      })}
                                    >
                                      <div className="approval-flow-approvers-container">
                                        <NewMinApproversBadge
                                          minValue={step.min_approvers_counter_condition}
                                          stepApproverLen={step.approvers_ids.length}
                                          mode="form"
                                          approvalOption={step.approval_option}
                                        />
                                        {step.approvers_ids.length === 0 ? (
                                          <div className="no-users-assigned ml-1">
                                            {t("approvalFlows.errors_noAssignees")}
                                          </div>
                                        ) : (
                                          step.approvers_ids.map((userId, userIndex) => (
                                            <EmployeeInitialsBadge
                                              mode="form"
                                              key={userIndex}
                                              approverId={userId}
                                              employeesByUserId={employeesByUserId}
                                              onRemove={() => {
                                                const updatedApprovers = values.steps[index].approvers_ids.filter(
                                                  (id) => id !== userId
                                                );
                                                setFieldValue(`steps.${index}.approvers_ids`, updatedApprovers);
                                                const newMinApprovers = Math.min(
                                                  values.steps[index].min_approvers_counter_condition,
                                                  updatedApprovers.length
                                                );
                                                setFieldValue(
                                                  `steps.${index}.min_approvers_counter_condition`,
                                                  newMinApprovers
                                                );
                                              }}
                                              className="ml-1"
                                            />
                                          ))
                                        )}
                                      </div>
                                    </div>

                                    <ErrorMessage
                                      name={`steps.${index}.approvers_ids`}
                                      component="div"
                                      className="text-danger"
                                    />
                                  </div>
                                </Col>
                                {index < values.steps.length - 1 && (
                                  <div className="approval-flow-step-arrow">
                                    <span className="arrow">></span>
                                  </div>
                                )}
                              </React.Fragment>
                            );
                          })}
                          <div className="approval-flow-add-step-container">
                            <span
                              className={cx("approval-flow-add-step", {
                                disabled: !!Object.keys(errors?.steps || {}).length,
                              })}
                              onClick={() => {
                                if (Object.keys(errors?.steps || {}).length === 0) {
                                  push({ ...emptyStep });
                                  setActiveStep(values.steps.length);
                                }
                              }}
                            >
                              {values.steps.length < 3 && <i className="fe-plus" />}
                            </span>
                          </div>
                          {Array.from({ length: 4 - values.steps.length }).map((__, index) => (
                            <Col key={`empty-col-${index}`} />
                          ))}
                        </Row>

                        {values.steps[activeStep] && (
                          <>
                            <Row className="mb-3">
                              <Col>
                                <CompanyEmployeePicker
                                  name={`steps.${activeStep}.approvers`}
                                  label={t("approvalFlows.labels_assignUsers")}
                                  placeholder={t("common:actions.select")}
                                  required
                                  companyId={companyId}
                                  isMulti
                                  onChange={(employees) => {
                                    const updatedApproversIds = employees.map((emp) => emp.user_id);
                                    setFieldValue(`steps.${activeStep}.approvers_ids`, updatedApproversIds);
                                  }}
                                  excludedUserIds={excludedUserIds}
                                  helpText={t("approvalFlows.helpText_assignUsers")}
                                  options={Object.values(employeesByUserId)}
                                />
                              </Col>
                              <Col />
                            </Row>

                            <Row className="mb-3">
                              <Col lg={3}>
                                <FormGroup.InlineOptionsSelect
                                  label={t("approvalFlows.labels_mandatoryApprovalFrom")}
                                  name={`steps.${activeStep}.approval_option`}
                                  options={[
                                    { value: "all", label: t("approvalFlows.options.mandatoryApprovalFrom_all") },
                                    { value: "any", label: t("approvalFlows.options.mandatoryApprovalFrom_any") },
                                    { value: "other", label: t("approvalFlows.options.mandatoryApprovalFrom_other") },
                                  ]}
                                  onChange={(newValue) => {
                                    const approversCount = values.steps[activeStep].approvers_ids?.length ?? 0;
                                    if (newValue === "all") {
                                      setFieldValue(
                                        `steps.${activeStep}.min_approvers_counter_condition`,
                                        approversCount
                                      );
                                    } else if (newValue === "any") {
                                      setFieldValue(`steps.${activeStep}.min_approvers_counter_condition`, 1);
                                    }
                                  }}
                                />
                              </Col>
                              <Col lg={3}>
                                <MinApproversInput
                                  name={`steps.${activeStep}.min_approvers_counter_condition`}
                                  label={t("approvalFlows.labels_minApprovers")}
                                  assignedUsersCount={values.steps[activeStep].approvers_ids.length}
                                  onChange={(newValue) => {
                                    const approversCount = values.steps[activeStep].approvers_ids?.length ?? 0;
                                    if (newValue === "1") {
                                      setFieldValue(`steps.${activeStep}.approval_option`, "any");
                                    } else if (newValue === approversCount) {
                                      setFieldValue(`steps.${activeStep}.approval_option`, "all");
                                    }
                                  }}
                                />
                              </Col>
                            </Row>
                          </>
                        )}
                      </>
                    )}
                  </FieldArray>
                  <Row className="mt-4">
                    <Col>
                      <FormGroup.Input name="title" label={t("approvalFlows.labels_name")} required />
                      <FormGroup.LabeledCheckbox
                        name="is_default"
                        label={t("approvalFlows.labels_isDefault")}
                        disabled={approvalFlowsDataLen === 1 && initialData.is_default}
                      />
                    </Col>
                    <Col />
                    <Col />
                    <Col />
                  </Row>
                </Card.Body>
                <Card.Footer>
                  {initialData?.id && !initialData.is_default && (
                    <RemoveButton
                      icon=""
                      label={t("approvalFlows.buttons_deleteFlow")}
                      className="btn-outline-red float-left"
                      onClick={() => onDelete(initialData?.id, setErrors)}
                      confirmMessage={t("approvalFlows.buttons_deleteFlowConfirm", { title: values.title })}
                    />
                  )}
                  <SubmitButton className="float-right" isSubmitting={isSubmitting} />
                  <Button className="float-right" variant="link" onClick={onCancel}>
                    {t("common:actions.cancel")}
                  </Button>
                </Card.Footer>
              </Card>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
}

export default ApprovalFlowForm;
